import React from "react";
import {
  HeroRight,
  HeroLeft,
  WebinarsHeroContainer,
  WebinarsHeroInner,
} from "./styles/webinarsPageStyles";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { toTitleCase } from "components/common/util/helperFunctions";
import RhombusButton from "components/common/RhombusButton";
import FeaturedWebinar from "./FeaturedWebinar";
import { css } from "linaria";

const heroButtonClass = css`
  margin-top: 2.5rem;
  @media (max-width: 600px) {
    margin-bottom: 2.5rem;
  }
`;

const WebinarsHero = () => {
  return (
    <WebinarsHeroContainer>
      <WebinarsHeroInner>
        <HeroLeft>
          <TextContainer width="665px">
            <PreTitle>webinars</PreTitle>
            <FancyPageTitle>
              {toTitleCase(
                "Learn more through live interactions and on-demand content"
              )}
            </FancyPageTitle>
            <RhombusButton
              title="Explore All Webinars"
              path="#webinars-feed"
              className={heroButtonClass}
            />
          </TextContainer>
        </HeroLeft>
        <HeroRight>
          <FeaturedWebinar />
        </HeroRight>
      </WebinarsHeroInner>
    </WebinarsHeroContainer>
  );
};

export default WebinarsHero;
