import React from "react";

const IconTime = ({ height = "26", width = "25", color = "currentColor" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 26"
      fill="none"
      style={{ flexShrink: 0 }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9144 22.159H3.84322C2.2792 22.159 1 20.9033 1 19.3679V6.28765C1 4.7533 2.2792 3.49756 3.84322 3.49756H17.9144C19.4774 3.49756 20.7576 4.7533 20.7576 6.28765V19.3679C20.7576 20.9033 19.4774 22.159 17.9144 22.159Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M1 9.49052H20.7566"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6 6V1"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M15.5 6V1"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default IconTime;
