import { format, toZonedTime } from "date-fns-tz";
import { format as formatDate } from "date-fns";

export const formatISODateWithTimeZone = (ISODate, timeZone) => {
  if (!ISODate) return null;

  const zonedDate = toZonedTime(ISODate, "Etc/UTC");
  const timeZoneAbbreviation = format(zonedDate, "zzz", { timeZone });
  const date = formatDate(zonedDate, "eeee, MMMM do 'at' h:mmaaa");

  return `${date} ${timeZoneAbbreviation}`;
};

export const isExpired = date => {
  const _date = new Date(date);
  const now = Date.now();
  return now >= _date;
};
