import {
  SelectionContainer,
  SelectionTitle,
  Selection,
} from "components/integrations/marketplace/styles/MarketPlace-css";
import React, { useCallback } from "react";
import { SidebarContainer } from "./styles/webinarsPageStyles";
import { COLLECTIONS } from "./util/webinarsFunctions";
import { scrollToTop } from "components/accessories/v2/util";
import { MOBILE_MEDIA_QUERY } from "components/accessories/v2/AccessoriesContainer";
import useIsMobile from "hooks/useIsMobile";

const WebinarSidebar = ({
  topics,
  setActiveFilter,
  activeFilter,
  setModalOpen,
}) => {
  const { isMobile } = useIsMobile(MOBILE_MEDIA_QUERY);

  const handleSelect = useCallback(
    filter => {
      setActiveFilter(filter);
      if (isMobile) {
        setModalOpen(false);
        scrollToTop("#webinars-feed");
      }
    },
    [isMobile]
  );
  return (
    <SidebarContainer>
      <SelectionContainer>
        <SelectionTitle>Collections</SelectionTitle>
        <Selection
          onClick={() => {
            handleSelect("All");
          }}
          active={activeFilter === "All"}
        >
          All Webinars
        </Selection>
        {COLLECTIONS.map(item => (
          <Selection
            key={item.type}
            onClick={() => {
              handleSelect(item.type);
            }}
            active={activeFilter === item.type}
          >
            {item.name}
          </Selection>
        ))}
      </SelectionContainer>
      <SelectionContainer>
        <SelectionTitle>Topics</SelectionTitle>
        <Selection
          onClick={() => {
            handleSelect("All Topics");
          }}
          active={activeFilter === "All Topics"}
        >
          All Topics
        </Selection>
        {topics.map(item => (
          <Selection
            key={item}
            onClick={() => {
              handleSelect(item);
            }}
            active={activeFilter === item}
          >
            {item}
          </Selection>
        ))}
      </SelectionContainer>
    </SidebarContainer>
  );
};

export default WebinarSidebar;
