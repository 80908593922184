import React from "react";
import svg from "../integrations/marketplace/img/not-found.svg";
import { styled } from "linaria/react";
import { MainParagraph } from "components/rhombus-UI/theme/typography";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.25rem;
  p {
    font-size: 22px;
    text-align: center;
  }
`;

const NotFoundImage = styled.img`
  width: 100%;
  max-width: 250px;
`;

const NotFound = ({ errorMessage }) => {
  return (
    <Container>
      <NotFoundImage src={svg} alt="Not Found" />
      <MainParagraph color="var(--blue-900)">{errorMessage}</MainParagraph>
    </Container>
  );
};

export default NotFound;
